import {useEffect} from 'react'
import {focusElement} from '@wix/panda-js-utils'
import {FocusHandlerProps} from './interfaces'

export const FocusHandler = ({activeElement, setActiveElement}: FocusHandlerProps) => {
  useEffect(() => {
    if (activeElement) {
      try {
        focusElement({selector: activeElement, canFocus: true})
      } catch (e) {
        console.log(e)
      } finally {
        setActiveElement(null)
      }
    }
  }, [activeElement])
  return null
}
